<template>
    <page ref='wrap'>
        <div ref='content'>
            <div class="f_flex f_a_c f_j_sb searchPanel">
                <div class="f_g1">
                    <div data-v-853cf3f8="" class="van-search" style="background: rgb(246, 246, 246);">
                        <div data-v-853cf3f8="" class="van-search__content van-search__content--round">
                            <div data-v-853cf3f8="" class="van-cell van-cell--borderless van-field">
                                <div class="van-field__left-icon"><i class="van-icon van-icon-search"><!----></i></div>
                                <div class="van-cell__value van-cell__value--alone van-field__value">
                                    <div class="van-field__body"><input type="search" placeholder="搜索"
                                                                        ref="input-search"
                                                                        autofocus="autofocus"
                                                                        v-model="word"
                                                                        @input="changeSearchFn"
                                                                        class="van-field__control"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form  @submit.prevent action="#">
                    <search v-show="false" shape='round' background="#f6f6f6"  @input='changeSearchFn' placeholder="搜索"
                            ref="search-input"></search>

                    <!-- <input type="search" style='font-size: 14px; background: #F1F1F1; margin-left: 12px; border: 0; width: 100%'
                            placeholder="搜索"
                            v-model="searchWord"
                            ref="search-input"
                            @keydown.enter="changeSearchFn"
                        /> -->
                    </form>
                </div>
                <div class="buildTxt theme_c" @click='$router.back()'>取消</div>
            </div>
            <tabs sticky v-model='active' @change='changeTab' color='#63A8FF' line-height='4' line-width='40'
                  title-inactive-color='#9B9B9B'>
                <div style="width: 100%; height: 8px; background: #f6f6f6"></div>
                <tab name='zonghe'>
                    <div slot='title'>动态</div>
                    <div class="searchContent" v-if='result.length && active =="zonghe"'>
                        <div>
                            <info-panel
                                v-for='(item, index) in result'
                                :key='index'
                                :info='item'
                                :index='index'>
                            </info-panel>
                            <div class="endTitleStyle" v-if='endTitle && isEnd'>{{ endTitle }}</div>
                        </div>
                    </div>
                    <empty v-else description='无内容'></empty>
                </tab>
                <tab name='myPost'>
                    <div slot='title'>我的</div>
                    <div class="searchContent" v-if='result.length && active =="myPost"'>
                        <info-panel
                            v-for='(item, index) in result'
                            :key='index'
                            :info='item'
                            :index='index'>
                        </info-panel>
                    </div>
                    <empty v-else description='无内容'></empty>
                </tab>
                
                <tab name='square'>
                    <div slot='title'>广场</div>
                    <div class="searchContent" v-if='result.length && active =="square"'>
                        <info-panel
                            v-for='(item, index) in result'
                            :key='index'
                            :info='item'
                            :keywordEvent='false'
                            :index='index'>
                        </info-panel>
                    </div>
                    <empty v-else description='无内容'></empty>
                </tab>
                <tab name='private'>
                    <div slot='title'>私密本</div>
                    <div class="searchContent" v-if='result.length && active == "private"'>
                        <info-panel
                            v-for='(item, index) in result'
                            :key='index'
                            :info='item'
                            :keywordEvent='false'
                            :index='index'>
                        </info-panel>
                    </div>
                    <empty v-else description='无内容'></empty>
                </tab>
            </tabs>
        </div>
    </page>
</template>

<script>
import {Search, Tabs, Tab, Empty} from 'vant'
import postApi from '@/api/post'
import infoPanel from '@/components/infoPanel/index.vue'
import scrollMixins from '@/common/mixins/scroll'
import {mapGetters} from 'vuex'

export default {
    components: {
        Search,
        Tabs,
        Tab,
        infoPanel,
        Empty
    },
    data: () => ({
        word: '',
        active: 'zonghe',
        result: [],
        resultMap:{},  //防止多次请求去重
        pageNo: 1,
        pageSize: 20,
        isLoading: false,
        isEnd: false,
        endTitle: '',
        pageLoaded: false
    }),
    mixins: [scrollMixins],
    computed:{
       ...mapGetters([
        'get_searchWord'
        ]),
    },
    watch: {
       "get_searchWord" : {
           handler: function() {
               console.log("get_searchWord change")
            if (this.get_searchWord) {
               
                this.word = this.get_searchWord
                if (this.pageLoaded) {
                    this.init ()
                    this.getList ()
                }
                
            } 
           },
           immediate: true
        }
    },
    methods: {
        changeSearchFn (n) {
            console.log ("changeSearchFn",n);
            this.resultMap = {}
            this.$global.debounce (() => {
                if (n) {
                    this.init ()
                    this.getList ()
                } else {
                    this.init ()
                }
            }, 500)
        },
        getList () {
            if (!this.isEnd && this.word) {
                const obj = {
                    'zonghe': 1,
                    'myPost': 2,
                    'square': 3,
                    'private': 4
                }
                const {bizId} = this.$route.query
                const company = this.$global.getItem ('companyInfo')
                console.log("search requst", {pageNo: this.pageNo, pageSize: this.pageSize})
                postApi.searchPost ({
                    searchWord: this.word,
                    bizType: obj[this.active],
                    bizId: bizId || company.companyId,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }).then (res => {
                    if (res.data) {
                        if (res.data.endFlag) {
                            this.isEnd = true
                        } else {
                            this.pageNo++
                        }
                        if (!this.endTitle) {
                            this.endTitle = res.data.endTitle
                        }
                        this.isLoading = false
                        //this.result.push (...res.data.postsList)
                        let resPostList = res.data.postsList
                        console.log("服务器返回的数据数量：", resPostList.length)
                        for (let i =0; i < resPostList.length; i++) {
                            
                            
                            if (this.resultMap[resPostList[i].postsId]) {
                                console.log("重复数据：", resPostList[i].publicTime)
                                console.log("重复数据 postId: ", resPostList[i].postsId)
                            } else {
                                this.resultMap[resPostList[i].postsId] = true
                                console.log("页面展示数据：", resPostList[i].publicTime)
                                console.log("页面展示数postId: ", resPostList[i].postsId)
                                this.result.push(resPostList[i])
                            }

                        }
                        
                    } else {
                        this.$notify ({
                            message: `0条结果`,
                            type: 'warning'
                        })
                    }
                })
            }
        },
        init () {
            this.pageNo = 1
            this.isEnd = false
            this.result = []
            this.isLoading = false
            this.resultMap = {}
        },
        changeTab () {
            this.init ()
            this.getList ()
        },
        scrollFn (e) {
            this.$global.debounce (() => {
                const wrapH = this.$refs.wrap.clientHeight
                const contentH = this.$refs.content.clientHeight
                if (contentH - e.target.scrollTop - wrapH <= 80) {
                    this.getList ()
                }
            })
        },
    },
    directives:{
      'focus':{
          inserted(el){
              el.focus()
          },
      }
    },
    mounted () {
        console.log('search mounted')
        this.pageLoaded = true
        
        
    },
    beforeRouteLeave (to, form, next) {
        //this.word = ''
        this.pageNo = 1
        this.result = []
        this.resultMap = {}
        next()
    },
    beforeRouteEnter (to, from, next) {
        console.log("search router enter")
        next(vm => {
            console.log(vm.word)
            const wrap = vm.$refs.wrap.$refs.pageContent_contain
            const content = vm.$refs.content
            if (vm.word) {
                vm.init ()
                vm.getList ()
                 vm.onContentScroll (wrap, content, vm.isEnd, () => {
                    vm.getList ()
                })
                 
            }
            
        })
    },
}
</script>


<style lang="less" scoped>
.search-input {

}

.searchPanel {
    background: #f6f6f6;
}

.buildTxt {
    font-size: 15px;
    margin-left: 11px;
    margin-right: 14px;
}

.van-search__content--round {
    border-radius: 999px;
    background: #fff;
}

.searchContent {
    padding: 0 15px;
}

.wrap {
    position: absolute;
    width: 100%;
    height: 92vh;
    top: 8vh;
    overflow: auto;
}

.endTitleStyle {
    color: #999;
    text-align: center;
    margin: 20px 0;
}
</style>
